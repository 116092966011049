import { useEffect, useState } from 'react'
import { ObjectManage } from './data'
import { QuickEvent } from './QuickEvent'

class SystemInfo extends ObjectManage {
  constructor() {
    super({})
    this.init()
  }

  data = {
    OSType: 'pc',
    windowWidth: document.body.clientWidth,
    windowHeight: document.body.clientHeight
  }

  changeEvent = new QuickEvent()

  onChange = this.changeEvent.on

  init = () => {
    const getOSType = () => {
      const os = (function () {
        const ua = navigator.userAgent,
          isWindowsPhone = /(?:Windows Phone)/.test(ua),
          isSymbian = /(?:SymbianOS)/.test(ua) || isWindowsPhone,
          isAndroid = /(?:Android)/.test(ua),
          isFireFox = /(?:Firefox)/.test(ua),
          isChrome = /(?:Chrome|CriOS)/.test(ua),
          isTablet =
            /(?:iPad|PlayBook)/.test(ua) ||
            (isAndroid && !/(?:Mobile)/.test(ua)) ||
            (isFireFox && /(?:Tablet)/.test(ua)),
          isPhone = /(?:iPhone)/.test(ua) && !isTablet,
          isPc = !isPhone && !isAndroid && !isSymbian
        return {
          isTablet: isTablet,
          isPhone: isPhone,
          isAndroid: isAndroid,
          isPc: isPc
        }
      })()
      if (os.isAndroid || os.isPhone) {
        this.data.OSType = 'phone'
      } else if (os.isTablet) {
        this.data.OSType = 'tablet'
      } else if (os.isPc) {
        this.data.OSType = 'pc'
      }
    }
    getOSType()
    window.addEventListener('resize', () => {
      this.data.windowWidth = document.body.clientWidth
      this.data.windowHeight = document.body.clientHeight
      getOSType()
      this.data = { ...this.data }
      this.changeEvent.trigger(this.data)
    })
  }

  useInfo = () => {
    const [data, setData] = useState(systemInfo.data)

    useEffect(() => {
      const { remove } = systemInfo.onChange(setData)
      return () => remove()
    }, [])

    return data
  }
}

export const systemInfo = new SystemInfo({})
