import { useState } from 'react'
import { Page } from '../Page'
import bg from './images/start-bg.png'
import styles from './index.module.scss'
import { request, user } from '../../utils'

export const Login = ({ children }) => {

  const [post, setPost] = useState({})

  const { token } = user.useData()

  const [msg, setMsg] = useState('')

  const submit = async () => {
    if (!post.username) {
      return setMsg('请输入账号')
    }
    if (!post.password) {
      return setMsg('请输入密码')
    }
    try {
      setMsg('')
      const res = await request({
        path: 'oauth/login',
        method: 'POST',
        data: post
      })
      user.set(res)
    } catch (error) {
      setMsg(error.message)
      console.log(error)
    }
  }

  if (token) {
    return children
  }

  return <Page className={styles.page}>
    <img src={bg} alt='' className={styles.bg} />
    <div className={styles.login}>
      <div className='left'>
        <img className='logo' alt='' src={require('./images/logo.png')} />
        <div className='name'>国资燃气热力公司集成平台系统</div>
      </div>
      <div className='right'>
        <div className='title'>欢迎登录国资燃气热力集成系统</div>
        <div className='label account'>账号</div>
        <input className='input' placeholder='请输入账号' onInput={e => setPost({ ...post, username: e.target.value })} />
        <div className='label'>密码</div>
        <input className='input' placeholder='请输入账号' type='password' onInput={e => setPost({ ...post, password: e.target.value })} />
        <div className={msg ? 'err show' : 'err'}>{msg || '空'}</div>
        <div className='submit' onClick={submit}>登录</div>
      </div>
    </div>
  </Page>
}
