import hmacSha256 from 'crypto-js/hmac-sha256'
import encHex from 'crypto-js/enc-hex'
import qs from 'qs'
import config from '@/config'
import { user } from './user'

export const request = async ({
  path,
  method = 'GET',
  query = {},
  data
}) => {

  const url = `${config.request.origin}/${config.request.path}/${path}`

  if (method === 'GET') {
    query = { ...query, ...data }
  }

  const timestamp = Math.round(new Date().getTime() / 1000)
  const paths = url.split('/').slice(3)
  const contentDate = timestamp.toString()

  const signData = []
  signData.push('/' + paths.join('/'))
  signData.push(qs.stringify(query, { encode: false }))
  signData.push(contentDate)

  const hash = hmacSha256(signData.join('\n'), config.request.sign)
  const sign = encHex.stringify(hash)

  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    AccessKey: config.request.accessKey,
    'Content-MD5': sign,
    'Content-Date': contentDate
  }

  if(user.data?.token) {
    headers.Authorization = user.data.token
  }

  try {
    const requestData = {
      method,
      headers
    }
    if (method !== 'GET' && data) {
      requestData.body = JSON.stringify(data)
    }
    const res = await fetch(`${url}${Object.keys(query).length ? ('?' + qs.stringify(query, { encode: false })) : ''}`, requestData)
    if (res.status === 200) {
      const data = await res.json()
      return data.data
    }
    throw await res.json()
  } catch (error) {
    // eslint-disable-next-line no-throw-literal
    throw {
      code: error.status || 500,
      message: error.message
    }
  }
}