import { ObjectManage } from '@/utils'

class User extends ObjectManage {

  constructor() {
    super({
      cache: true,
      cacheKey: 'userInfo'
    })
  }

  login = () => {

  }
}

export const user = new User()