import { useEffect, useMemo, useRef, useState } from 'react'
import { Page } from '../components'
import bg from './images/index-bg.png'
import styles from './index.module.scss'
import { colorDark, colorToRgb } from '@/utils'
import { Swiper, SwiperSlide } from 'swiper/react'
import { request } from '../utils'

import 'swiper/css'

export default function Index() {

  const swiper = useRef()

  const [list, setList] = useState([])

  useEffect(() => {
    request({
      path: 'oauth/oauth'
    }).then(res => {
      setList(res.data)
      console.log(res)
    })
  }, [])

  const renderList = useMemo(() => {
    return list.reduce((prev, item, index) => {
      const i = index / 6 | 0
      if (!prev[i]) {
        prev[i] = []
      }
      prev[i].push(item)
      return prev
    }, [])
  }, [list])

  return <Page className={styles.page}>
    <img src={bg} alt='' className={styles.bg} />
    <div className={styles.title}>国资燃气热力公司集成平台系统</div>
    <Swiper
      // onSlideChange={() => console.log('slide change')}
      onSwiper={e => swiper.current = e}
    >
      {
        renderList.map((group, gi) => <SwiperSlide key={gi}>
          <div className={styles.list}>
            {
              group.map(item => <Item key={item.name} {...item} />)
            }
          </div>
        </SwiperSlide>)
      }
      <img className='nav left' alt='' src={require('./images/index-nav1.png')}
        onClick={() => swiper.current.slidePrev()}
      />
      <img className='nav right' alt='' src={require('./images/index-nav2.png')}
        onClick={() => swiper.current.slideNext()}
      />
    </Swiper>
  </Page>
}

// const menus = [
//   [
//     {
//       name: '智慧调度服务平台',
//       icon: require('./images/menu1.png'),
//       color: '#0768fa'
//     },
//     {
//       name: '燃气热力客服平台',
//       icon: require('./images/menu2.png'),
//       color: '#3aedfd'
//     },
//     {
//       name: '综合安防管理平台',
//       icon: require('./images/menu3.png'),
//       color: '#e6b777'
//     },
//     {
//       name: '协同办公OA系统',
//       icon: require('./images/menu4.png'),
//       color: '#32ecfc'
//     },
//     {
//       name: '客户信息管理系统',
//       icon: require('./images/menu5.png'),
//       color: '#d75efa'
//     },
//     {
//       name: '智慧供热系统',
//       icon: require('./images/menu6.png'),
//       color: '#f252a9'
//     },
//   ],
//   [
//     {
//       name: '采购管理系统',
//       icon: require('./images/menu7.png'),
//       color: '#17e0ed'
//     }
//   ]
// ]

let idkey = 0
const Item = ({
  color,
  icon,
  title,
  url
}) => {

  const id = useMemo(() => {
    return `svg-lg-${++idkey}`
  }, [])

  const rgb = colorToRgb(color)

  const colors = useMemo(() => {
    return Array(7).fill(1).map((_item, index) => {
      return colorDark(color, index * 0.02)
    })
  }, [color])

  return <div className='item' onClick={() => window.open(url)}>
    <svg viewBox='0 0 427 230'>
      <defs>
        <linearGradient id={id} x1='100%' y1='0%' x2='0%' y2='100%'>
          <stop offset='0%' stopColor={`rgba(${rgb.join(',')},0.6)`}></stop>
          <stop offset='100%' stopColor={`rgba(${rgb.join(',')},0)`}></stop>
        </linearGradient>
      </defs>
      <polygon fill={`url(#${id})`} points='13,12 120,12 133,1 426,1 426,210 411,229 1,229 1,29' strokeWidth={2} stroke={color}></polygon>
      <g strokeWidth={5} stroke={color} fill='transparent'>
        <path d='M36 14 15 14 2 29 2 48'></path>
        <path d='M396 2 424 2 424 22'></path>
        <path d='M424 170 424 210 411 227 331 227'></path>
        <path d='M2 209 2 227 22 227'></path>
      </g>
      <g>
        {
          colors.map((item, index) => <polygon
            key={index}
            points={`${118 - index * 14},0 ${128 - index * 14},0 ${116 - index * 14},10 ${106 - index * 14},10`}
            fill={item}
          ></polygon>)
        }
      </g>
    </svg>
    <img src={icon} alt='' className='icon' />
    <div className='name'>{title}</div>
  </div>
}